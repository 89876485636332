import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import {ThemeProvider} from "@mui/material";

import {ModalProvider} from "@/providers/ModalProvider.jsx";
import defaultTheme from "@/themes/defaultTheme.jsx";

const ViewTracking = React.lazy(() => import("@/views/tracking/ViewTracking.jsx"));
const SignUp = React.lazy(() => import("@/views/sign-up/SignUp.jsx"));
const SignIn = React.lazy(() => import("@/views/auth/SignIn.jsx"));
const ResetPassword = React.lazy(() => import("@/views/auth/ResetPassword.jsx"));
const ResendVerificationEmail = React.lazy(() => import("@/views/auth/ResendVerificationEmail.jsx"));
const ContactUs = React.lazy(() => import("@/views/contact-us/ContactUs.jsx"));
const JobsSearch = React.lazy(() => import("@/views/jobs-search/JobsSearch.jsx"));
const ResumesSearch = React.lazy(() => import("@/views/resumes-search/ResumesSearch.jsx"));
const ProfileEdit = React.lazy(() => import("@/views/dashboard/edit/ProfileEdit.jsx"));
const ProfileEditPassword = React.lazy(() => import("@/views/dashboard/edit/ProfileEditPassword.jsx"));
const UpdatePaymentMethod = React.lazy(() => import("@/views/dashboard/edit/UpdatePaymentMethod.jsx"));
const Posting = React.lazy(() => import("@/views/posting/Posting.jsx"));
const PostingEdit = React.lazy(() => import("@/views/posting/PostingEdit.jsx"));
const Apply = React.lazy(() => import("@/views/apply/Apply.jsx"));

if (document.getElementById('sign-in-form')) {
    hydrateRoot(
        document.getElementById('sign-in-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <SignIn/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('sign-up-form')) {
    hydrateRoot(
        document.getElementById('sign-up-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <SignUp/>
            </ModalProvider>
        </ThemeProvider>
    );
}


if (document.getElementById('profile-edit-form')) {
    const root = createRoot(document.getElementById('profile-edit-form'));
    root.render(
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <ProfileEdit/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('profile-edit-password-form')) {
    hydrateRoot(
        document.getElementById('profile-edit-password-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <ProfileEditPassword/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('update-payment-method-form')) {
    hydrateRoot(
        document.getElementById('update-payment-method-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <UpdatePaymentMethod/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('reset-password-form')) {
    hydrateRoot(
        document.getElementById('reset-password-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <ResetPassword/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('resend-verification-email-form')) {
    hydrateRoot(
        document.getElementById('resend-verification-email-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <ResendVerificationEmail/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('contact-us-form')) {
    hydrateRoot(
        document.getElementById('contact-us-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <ContactUs/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('search-jobs-filter')) {
    const redirect = document.getElementById('search-jobs-filter').getAttribute('redirect') === 'true';

    hydrateRoot(
        document.getElementById('search-jobs-filter'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <JobsSearch redirect={redirect}/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('search-resumes-filter')) {
    const redirect = document.getElementById('search-resumes-filter').getAttribute('redirect') === 'true';

    hydrateRoot(
        document.getElementById('search-resumes-filter'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <ResumesSearch redirect={redirect}/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if(document.getElementsByClassName("btn-favourite")?.length){
    import("@/components/favorites/HandleFavoriteButtons.jsx").then(module => {
        // Call the function after it's imported
        const HandleFavoriteButtons = module.default;
        HandleFavoriteButtons();
    });
}

if (document.getElementById('posting-form')) {
    hydrateRoot(
        document.getElementById('posting-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <Posting/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('posting-edit-form')) {
    const posting_id = document.getElementById('posting-edit-form').getAttribute('data');

    hydrateRoot(
        document.getElementById('posting-edit-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <PostingEdit posting_id={posting_id}/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('apply-form')) {
    const data = JSON.parse(document.getElementById('apply-form').getAttribute('data'));

    hydrateRoot(
        document.getElementById('apply-form'),
        <ThemeProvider theme={defaultTheme}>
            <ModalProvider>
                <Apply {...data}/>
            </ModalProvider>
        </ThemeProvider>
    );
}

if (document.getElementById('view-tracking')) {
    hydrateRoot(
        document.getElementById('view-tracking'),
        <ViewTracking />
    );
}
